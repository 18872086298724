*,
::after,
::before {
  box-sizing: border-box;
}
body {
  font-size: 15px;
  line-height: 1.6;
  font-family: "Livvic", sans-serif;
  color: #444444;
  font-weight: 400;
}
/* navbar stlye */
.navbar-toggler {
  border: 0;
}
.logo {
  height: 43px;
  margin: 5px 10px;
}
.coles {
  background: #ffffff !important;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  color: #000000;
}
.collapse h3 {
  display: inline;
  padding: 0 20px;
}
.cart {
  color: #000000;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
}
.cart:hover {
  color: #0071dc;
  border-bottom: 1px solid #0071dc;
  transition: 0.2s;
}
.collapse .active {
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  color: #0071dc;
  border-bottom: 1px solid #0071dc;
  width: 80px;
  cursor: pointer;
}
.stye {
  text-decoration: none;
  cursor: pointer;
}
.coles span {
  padding: 0 20px;
}
.coles span .fa {
  padding: 0 5px;
}
.scr {
  position: fixed;
  background: #0071dc;
  backdrop-filter: blur(5px);
  border: 0px;
  border-radius: 50%;
  color: #fff;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 1;
}
.scr:hover {
  transition: 0.9s;
  background: #ffc221;
}
.ad .fa {
  font-size: 35px;
}
/* service page style */
.banner-four-area {
  font-size: 24px;
  padding: 76px 0 50px;
  background-color: #070b3b;
  z-index: 1;
  position: relative;
}
.banner-four-area::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./component/asset/home-four-bg.png);
  opacity: 0.8;
}
.banner-four-content {
  position: relative;
  z-index: 1;
}
.banner-four-content h1 {
  font-weight: 600;
  line-height: 1.2;
}
.banner-four-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}
.banner-four-content ul li {
  font-size: 18px;
  display: inline-block;
  padding-right: 10px;
}
.sig {
  color: white;
  text-decoration: none;
}
.sig:hover {
  transition: 0.5s;
  color: #ffc221;
}
.sty {
  padding-left: 100px;
}
.banner-four-img {
  position: relative;
  z-index: 1;
}
img {
  max-width: 100%;
}
.section-title span {
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 600;
  display: block;
}
.sp-color2 {
  color: #0071dc;
}
.section-title h2 {
  font-size: 35px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.2;
  margin-bottom: 0;
}
.blog-card {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgb(0 0 0 / 6%);
  margin-bottom: 30px;
  border-radius: 5px;
  transition: 0.9s;
  border: 1px solid #f1f1f1;
}
.blog-card:hover .lin {
  transition: 0.9s;
  color: #ffc221;
}
.blog-card:hover {
  transform: translateY(-6px);
}
.blog-card .blog-img {
  position: relative;
}
.blog-card img {
  max-width: 100%;
  border-radius: 5px;
}
.blog-card .content {
  padding: 20px 30px;
}
.blog-card .content h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
}
.blog-card .content p {
  margin-bottom: 8px;
  color: #444444;
  font-weight: 400;
}
.lin {
  display: inline-block;
  transition: all 0.5s;
  color: #252525;
  text-decoration: none;
}
.lin:hover {
  color: #ffc221;
}
.clients-area {
  position: relative;
  z-index: 1;
  background-color: #15163b;
}
.clients-area::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-image: url(./component/asset/client-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.clients-area .section-title h2 {
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}
.section-title h2 {
  font-size: 35px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.2;
  color: #252525;
  margin-bottom: 0;
}
.isd {
  height: 220px;
  margin: 10px 110px;
  position: relative;
  background-image: url(./component/asset/download.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}
.isd h3 {
  text-align: start;
  margin-left: -70px;
  font-size: 22px;
  font-weight: 600;
  color: #252525;
}
.isd p {
  text-align: justify;
  margin-left: -70px;
  margin-right: -70px;
  color: #444444;
  font-weight: 400;
  margin-top: 0;
}
@media only screen and (max-width: 780px) {
  .isd {
    height: 340px;
    margin: 10px;
  }
  .isd h3,
  .isd p {
    margin-left: -40px;
    margin-right: -40px;
  }
}
.circle {
  width: 40px;
  height: 40px;
  background-color: #0071dc;
  border-radius: 50px;
  position: relative;
  z-index: 1;
  border: 13px solid #f6fbff;
}
.client-circle-1 {
  position: absolute;
  top: 30%;
  left: 45px;
}
.client-circle-2 {
  position: absolute;
  top: 55%;
  left: 5%;
}
.client-circle-3 {
  position: absolute;
  top: 77%;
  left: 2%;
}
.client-circle-4 {
  position: absolute;
  top: 30%;
  right: 20%;
}
.client-circle-5 {
  position: absolute;
  top: 45%;
  right: 5%;
}
.client-circle-6 {
  position: absolute;
  top: 77%;
  right: 20px;
}
.client-circle-7 {
  position: absolute;
  bottom: 15%;
  right: 35%;
}
.aaa {
  pointer-events: all;
  cursor: pointer;
}
.footer-bg {
  background-color: #070b3b;
}
.copy-right-area {
  padding: 15px 0;
  border-top: 1px solid #0071dc61;
}
.copy-right-text p {
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 400;
  margin-top: 0;
}
.copy-right-text .a {
  color: #ffc221;
  border-bottom: 1px solid #ffc221;
  display: inline-block;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-decoration: none;
}
.copy-right-text .a:hover {
  color: #0071dc;
  border-bottom: 1px solid #0071dc;
}
.footer-widget {
  margin-bottom: 30px;
}
.footer-logo {
  margin-bottom: 20px;
  position: relative;
  top: -5px;
}
.footer-widget h3 {
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 30px;
  color: #ffffff;
  line-height: 1.2;
  font-weight: 600;
}
.footer-widget p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #ffffff;
  max-width: 350px;
  margin-top: 0;
  font-weight: 400;
}
.newsletter-form {
  position: relative;
  max-width: 370px;
  border-radius: 5px;
}
.form-control {
  line-height: 50px;
  margin: 0;
  border-radius: 5px;
  border: 0;
  padding: 0 45px 0 15px;
  max-width: 100%;
  color: #252525;
  font-weight: 400;
}
.subscribe-btn {
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: #0071dc;
  color: #ffffff;
  height: 45px;
  line-height: 50px;
  width: 45px;
  border: 0;
  border-radius: 5px;
  font-size: 20px;
  transition: 0.5s;
}
.subscribe-btn:hover {
  background-color: #000000;
}
.footer-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-list li {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}
.footer-list li .a {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}
.footer-list li .a:hover {
  color: #ffc221;
  transition: 0.5s;
}
.footer-list li .fa {
  margin-right: 0;
  font-size: 16px;
}
/* home page style */
.styls1 {
  height: 860px;
  position: relative;
  z-index: 1;
  background-image: url(./component/asset/home-one-img1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.styls2 {
  height: 860px;
  position: relative;
  z-index: 1;
  background-image: url(./component/asset/home-one-img2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.styls3 {
  height: 860px;
  position: relative;
  z-index: 1;
  background-image: url(./component/asset/home-one-img3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.styls1::before,
.styls2::before,
.styls3::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #070b3b;
  opacity: 0.8;
  -webkit-tap-highlight-color: transparent;
}
.capt {
  position: absolute;
  color: #f1f1f1;
  text-align: left;
  top: -690px;
}
.capt span {
  font-size: 15px;
  font-weight: 500;
  color: #0071dc;
  padding: 10px 25px;
  background-color: #ffffff;
  border-radius: 50px;
  display: inline-block;
}
.capt h1 {
  margin-top: 25px;
  font-size: 65px;
  color: #ffffff;
  font-weight: bold;
  max-width: 780px;
  margin-bottom: 20px;
}
.capt p {
  font-size: 17px;
  text-align: justify;
  color: #ffffff;
  font-weight: 400;
  max-width: 660px;
  margin-bottom: 35px;
}
@media only screen and (max-width: 780px) {
  .capt h1 {
    font-size: 35px;
  }
  .capt p {
    font-size: 15px;
  }
  .center {
    text-align: center;
  }
  .call-contact {
    text-align: center;
  }
}
.default-btn {
  display: inline-block;
  padding: 12px 32px;
  color: #ffffff;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: #ffc221;
  border-radius: 50px;
  text-decoration: none;
  font-size: 20px;
  z-index: 1;
  transition: background-color 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.default-btn:hover {
  background-color: #000000;
  transition: background-color 1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  background-image: linear-gradient(to top right, #000000);
}
.carousel-indicators button {
  margin: 7px 7px;
  width: 40px !important;
  height: 40px !important;
  border: 10px solid #f6fbff;
  border-radius: 50%;
  position: relative;
}
.carousel-indicators .active {
  background-color: #ffc221 !important;
}
.carousel-indicators button:hover {
  background-color: #ffc221 !important;
}
.work-process-area-two {
  position: relative;
}
.work-process-card {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  padding: 30px 20px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}
.work-process-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 70px;
  height: 55px;
  background-color: #0071dc;
  border-radius: 0 0 0 270px;
  opacity: 0.1;
  transition: 0.7s;
}
.work-process-card img {
  width: 85px;
}
.work-process-card h3 {
  margin-top: 15px;
  font-weight: 600;
  color: #252525;
  font-size: 20px;
  margin-bottom: 10px;
}
.work-process-card .number {
  font-size: 24px;
  color: #0071dc;
  font-weight: 600;
  position: absolute;
  top: 5px;
  right: 15px;
}
.choose-img-two {
  position: relative;
  z-index: 1;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.choose-img-two::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 90%;
  height: 100%;
  background-color: #070b3b;
}
.services-area-two {
  background-color: #070b3b;
  position: relative;
  z-index: 1;
}
.services-area-two::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(./component/asset/services-bg.png);
}
.section-title span {
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 600;
  display: block;
}
.sp-color1 {
  color: #ffc221;
}
.services-area-two .section-title h2 {
  max-width: 500px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}
.section-title h2 {
  font-size: 35px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.2;
  margin-bottom: 0;
}
.services-area .section-title p {
  color: #ffffff;
  max-width: 760px;
}
.section-title p {
  font-size: 18px;
  padding-top: 10px;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
}
.services-item {
  position: relative;
  margin-bottom: 80px;
  transition: 0.7s;
}
.services-item img {
  border-radius: 5px;
}
.services-item:hover .lin {
  transition: 0.9s;
  color: #ffc221;
}
.services-item:hover {
  transform: translateY(-6px);
}
.services-item .content {
  position: absolute;
  width: 95%;
  padding: 20px 15px 20px 92px;
  left: 0;
  right: 0;
  width: 82%;
  bottom: -80px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgb(0 0 0 / 5%);
  border-radius: 5px;
  margin-bottom: 30px;
}
.services-item .content img {
  width: 65px;
  position: absolute;
  top: 17px;
  left: 20px;
}
.services-item .content span {
  color: #0071dc;
}
.services-item .content h3 {
  font-size: 20px;
  margin-top: 3px;
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: 600;
  color: #252525;
}
.call-us-area {
  background-color: #070b3b;
  position: relative;
  z-index: 1;
}
.call-us-area::before {
  content: "";
  position: absolute;
  opacity: 0.1;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(./component/asset/call-shap1.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.call-contact {
  padding: 50px 0;
}
.call-contact a:hover {
  color: #ffc221;
}
.call-contact .call-btn {
  font-size: 35px;
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.5s;
  text-decoration: none;
}
.call-contact p {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 25px;
}
.btn-bg-two {
  background-color: #0071dc;
}
.border-radius-5 {
  border-radius: 5px;
}
.call-us-img {
  text-align: center;
  position: relative;
  z-index: 1;
}
.call-shape .shape1 {
  position: absolute;
  top: 30px;
  left: 20px;
}
.call-shape .shape2 {
  position: absolute;
  top: 30px;
  right: -35px;
}
.technology-area {
  position: relative;
  z-index: 1;
  background-image: url(./component/asset/technology-img.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.technology-area::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #070b3b;
  opacity: 0.8;
}
.technology-area .section-title h2 {
  color: #ffffff;
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  font-size: 35px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.2;
  margin-bottom: 0;
}
.technology-card {
  padding: 30px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
}
.technology-card h3 {
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}
.technology-card img {
  width: 60px;
}
/* aboutUs style */
.about-play {
  position: relative;
  border-radius: 30px;
}
.about-play img {
  border-radius: 30px;
}
.about-play .about-play-content {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  max-width: 470px;
  background-color: #25252538;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 70%;
  padding: 100px 40px 70px 25px;
}
.about-play .about-play-content::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 30px;
  width: 91.7%;
  height: 90.1%;
  background-color: #0071dc;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 70%;
}
.about-play-content span {
  color: #ffffff;
}
.about-play-content h2 {
  color: #ffffff;
  font-size: 35px;
  max-width: 300px;
  margin-bottom: 0;
  font-weight: 600;
}
.about-play-content .play-on-area {
  position: absolute;
  z-index: 1;
  bottom: 175px;
  left: 200px;
}
.play-on-area .play-on {
  width: 80px;
  height: 80px;
  color: #0071dc;
  background-color: #ffffff;
  text-align: center;
  font-size: 51px;
  border-radius: 50px;
  display: inline-block;
  transition: all 0.5s;
  text-decoration: none;
}
.about-play .about-play-content .play-on-area .play-on i {
  padding-left: 10px;
}
.section-title h2 {
  font-size: 35px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.2;
  color: #252525;
  margin-bottom: 0;
}
.section-title p {
  text-align: justify;
  font-size: 18px;
  padding-top: 10px;
  margin-bottom: 0;
  max-width: 530px;
  color: #444444;
  font-weight: 400;
  margin-top: 0;
}
.about-content .about-list {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
}
.about-content .about-list li {
  display: block;
  color: #252525;
  margin-bottom: 10px;
  font-weight: 500;
  padding-left: 35px;
  position: relative;
  transition: 0.5s;
  font-size: 18px;
}
.about-content .about-list li i {
  font-size: 26px;
  color: #0071dc;
  position: absolute;
  left: 0;
  top: -2px;
  margin-right: 5px;
  transition: 0.5s;
}
.about-list li:hover {
  color: #0071dc;
}
.about-list li i:hover {
  color: #ffc221;
}
@media only screen and (max-width: 767px) {
  .about-play .about-play-content {
    margin-top: 30px;
    position: inherit;
    background-color: #0071dc;
    border-radius: 10px;
    padding: 40px 30px 60px;
    max-width: 100%;
    text-align: center;
  }
  .play-content h2 {
    font-size: 24px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
  }
  .about-play .about-play-content .play-on-area {
    position: inherit;
    top: 15px;
    bottom: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .choose-content .choose-content-card .content {
    padding-left: 60px;
  }
  .choose-content .choose-content-card .content h3 {
    font-size: 20px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 15px;
  }
  .about-img::before {
    display: none;
  }
  .about-img {
    margin-top: 30px;
  }
}
.choose-area {
  background-color: #070b3b;
}
.choose-content {
  margin-bottom: 30px;
}
.choose-content .section-title h2 {
  max-width: 470px;
  color: #ffffff;
}
.choose-content .section-title p {
  color: #ffffff;
  text-align: justify;
  font-size: 18px;
  padding-top: 10px;
  margin-bottom: 0;
  max-width: 530px;
  font-weight: 400;
  margin-top: 0;
}
.choose-content .choose-content-card {
  margin-top: 15px;
}
.choose-content .choose-content-card .content {
  padding-top: 20px;
  position: relative;
}
.choose-content .choose-content-card .content img {
  width: 35px;
}
.choose-content .choose-content-card .content h3 {
  color: #ffffff;
  padding: 0 10px;
}
.choose-img {
  margin-bottom: 30px;
}
.choose-img img {
  border-radius: 15px;
}
.section-title h1 {
  text-align: center;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.2;
  padding-top: 10px;
  margin-bottom: 0;
  max-width: 530px;
  color: #444444;
}
.section-title h5 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #252525;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.section-title p {
  text-align: justify;
  font-size: 18px;
  font-weight: 400;
  padding-top: 10px;
  margin-bottom: 0;
  max-width: 530px;
  color: #444444;
}
.about-img {
  position: relative;
}
.about-img::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 30%;
  height: 40%;
  right: -15px;
  background-color: transparent;
  border: 3px solid #ffc221;
  bottom: -10px;
  border-radius: 35px;
}
.about-img img {
  border-radius: 15px;
}
title h2 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-size: 35px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.2;
  color: #252525;
  margin-bottom: 0;
}
.security-card {
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  padding: 30px 30px;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 0 0 15px rgb(0 0 0 / 50%);
  border-bottom: 3px solid #0071dc;
}
.security-card h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
  color: #252525;
  line-height: 1.2;
}
.security-card i {
  font-size: 45px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: #252525;
  background-color: #e5f3ff;
  border-radius: 50px;
  display: inline-block;
  margin-bottom: 12px;
  transition: 0.5s;
}
.security-card i img {
  width: 45px;
}
.security-card:hover h3 {
  color: #0071dc;
  transition: 0.9s;
}
/* contact page style */
.contact-info {
  margin-bottom: 30px;
  background-color: #070b3b;
  padding: 52px 35px 22px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
.contact-info::before {
  content: "";
  position: absolute;
  opacity: 0.1;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(./component/asset/call-shap1.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.contact-info span {
  color: #ffc221;
  display: block;
  margin-bottom: 5px;
}
.contact-info h2 {
  color: #ffffff;
  font-size: 26px;
  margin-bottom: 15px;
  font-weight: 600;
}
.contact-info p {
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 400;
}
.contact-info ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.contact-info ul li {
  display: block;
  margin-bottom: 30px;
  padding-left: 60px;
  position: relative;
}
ul li .content i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background-color: #ffffff;
  font-size: 20px;
  color: #0071dc;
  border-radius: 50px;
  border: 1px solid #e2e2e2;
  margin-bottom: 10px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
ul li .content h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ffffff;
  display: block;
  font-weight: 600;
}
ul li .content a {
  color: #ffffff;
  display: block;
  font-weight: 400;
  font-size: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
ul li .content span {
  color: #ffffff;
  font-weight: 400;
  display: block;
  font-size: 15px;
}
.contact-form {
  max-width: 800px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.form-group label {
  color: #252525;
  font-size: 15px;
  margin-bottom: 15px;
  font-weight: 500;
  padding-top: 10px;
}
.form-group label span {
  color: #ff0303;
}
.fstyle {
  background-color: #ffffff;
  border: 1px solid #0071dc;
  color: #252525;
  border-radius: 0;
}
.contact-form .default-btn {
  border: 0;
  outline: none;
}
/* careers page style */
.talk-area {
  background-image: url(./component/asset/talk-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.talk-area::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #070b3b;
  opacity: 0.8;
}
.talk-content .section-title {
  margin-bottom: 30px;
}
.talk-content .section-title h1 {
  font-size: 70px;
  color: #ffffff;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  margin-bottom: 0;
  text-align: justify;
  font-weight: 600;
}
.clients-slider-img {
  position: relative;
  z-index: 1;
  text-align: center;
}
.clients-slider-img img {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.clients-slider-img .clients-slider-circle {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: -35px;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  height: 90%;
  background-image: linear-gradient(#0071dc, #070b3b);
  animation: border-transform 15s infinite ease-in-out;
}
@keyframes border-transform {
  0%,
  100% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
  14% {
    border-radius: 50% 20% 50% 30% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}
.about-content-3 {
  max-width: 560px;
  margin-right: auto;
  margin-bottom: 30px;
}
.about-content-3 .section-title {
  margin-bottom: 20px;
}
.about-content-3 .section-title p {
  text-align: justify;
  font-size: 18px;
  padding-top: 10px;
  margin-bottom: 0;
  max-width: 530px;
  color: #444444;
  font-weight: 400;
}
.pabout {
  padding-left: 20px;
  font-weight: 400;
}
